
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const GET_PROFILE_SUCCESS = "LOGIN_SUCCESS";
export const GET_PROFILE_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const GET_COUNTRIES_SUCCESS = "GET_COUNTRIES_SUCCESS";
export const GET_COUNTRIES_FAIL = "GET_COUNTRIES_FAIL";

export const GET_PROVINCES_SUCCESS = "GET_PROVINCES_SUCCESS";
export const GET_PROVINCES_FAIL = "GET_PROVINCES_FAIL";

export const GET_LANGUAGES_SUCCESS = "GET_LANGUAGES_SUCCESS";
export const GET_LANGUAGES_FAIL = "GET_LANGUAGES_FAIL";

export const GET_COMMUNICATION_TYPES_SUCCESS = "GET_COMMUNICATION_TYPES_SUCCESS";
export const GET_COMMUNICATION_TYPES_FAIL = "GET_COMMUNICATION_TYPES_FAIL";

export const GET_BUSINESS_TYPES_SUCCESS = "GET_BUSINESS_TYPES_SUCCESS";
export const GET_BUSINESS_TYPES_FAIL = "GET_BUSINESS_TYPES_FAIL";

export const GET_BUSINESSES_SUCCESS = "GET_BUSINESSES_SUCCESS";
export const GET_BUSINESSES_FAIL = "GET_BUSINESSES_FAIL";
export const GET_BUSINESS_BY_ID_SUCCESS = "GET_BUSINESSES_SUCCESS";
export const GET_BUSINESS_BY_ID_FAIL = "GET_BUSINESSES_FAIL";
export const ADD_BUSINESSES_SUCCESS = "ADD_BUSINESSES_SUCCESS";
export const ADD_BUSINESSES_FAIL = "ADD_BUSINESSES_FAIL";
export const UPDATE_BUSINESS_SUCCESS = "UPDATE_BUSINESSES_SUCCESS";
export const UPDATE_BUSINESS_FAIL = "UPDATE_BUSINESSES_FAIL";
export const DELETE_BUSINESS_SUCCESS = "DELETE_BUSINESS_SUCCESS";
export const DELETE_BUSINESS_FAIL = "DELETE_BUSINESS_FAIL";

export const GET_CONTACTS_SUCCESS = "GET_CONTACTS_SUCCESS";
export const GET_CONTACTS_FAIL = "GET_CONTACTS_FAIL";
export const ADD_CONTACT_SUCCESS = "ADD_CONTACT_SUCCESS";
export const ADD_CONTACT_FAIL = "ADD_CONTACT_FAIL";
export const UPDATE_CONTACT_SUCCESS = "UPDATE_CONTACT_SUCCESS";
export const UPDATE_CONTACT_FAIL = "UPDATE_CONTACT_FAIL";
export const DELETE_CONTACT_SUCCESS = "DELETE_CONTACT_SUCCESS";
export const DELETE_CONTACT_FAIL = "DELETE_CONTACT_FAIL";
export const GET_CONTACT_BY_ID_SUCCESS = "GET_CONTACT_BY_ID_SUCCESS";
export const GET_CONTACT_BY_ID_FAIL = "GET_CONTACT_BY_ID_FAIL";
export const GET_CONTACTS_BY_BUSINESS_ID_SUCCESS = "GET_CONTACTS_BY_BUSINESS_ID_SUCCESS";
export const GET_CONTACTS_BY_BUSINESS_ID_FAIL = "GET_CONTACTS_BY_BUSINESS_ID_FAIL";

export const GET_COMMUNICATIONS_SUCCESS = "GET_COMMUNICATIONS_SUCCESS";
export const GET_COMMUNICATIONS_FAIL = "GET_COMMUNICATIONS_FAIL";
export const ADD_COMMUNICATIONS_SUCCESS = "ADD_COMMUNICATIONS_SUCCESS";
export const ADD_COMMUNICATIONS_FAIL = "ADD_COMMUNICATIONS_FAIL";
export const UPDATE_COMMUNICATIONS_SUCCESS = "UPDATE_COMMUNICATIONS_SUCCESS";
export const UPDATE_COMMUNICATIONS_FAIL = "UPDATE_COMMUNICATIONS_FAIL";
export const DELETE_COMMUNICATIONS_SUCCESS = "DELETE_COMMUNICATIONS_SUCCESS";
export const DELETE_COMMUNICATIONS_FAIL = "DELETE_COMMUNICATIONS_FAIL";

export const GET_EVENTS_SUCCESS = "GET_EVENTS_SUCCESS";
export const GET_EVENTS_FAIL = "GET_EVENTS_FAIL";
export const ADD_EVENTS_SUCCESS = "ADD_EVENTS_SUCCESS";
export const ADD_EVENTS_FAIL = "ADD_EVENTS_FAIL";
export const UPDATE_EVENTS_SUCCESS = "UPDATE_EVENTS_SUCCESS";
export const UPDATE_EVENTS_FAIL = "UPDATE_EVENTS_FAIL";
export const DELETE_EVENTS_SUCCESS = "DELETE_EVENTS_SUCCESS";
export const DELETE_EVENTS_FAIL = "DELETE_EVENTS_FAIL";

export const GET_EVENT_TYPES_SUCCESS = "GET_EVENT_TYPES_SUCCESS";
export const GET_EVENT_TYPES_FAIL = "GET_EVENT_TYPES_FAIL";

export const GET_QUOTES_SUCCESS = "GET_QUOTES_SUCCESS";
export const GET_QUOTES_FAIL = "GET_QUOTES_FAIL";

export const GET_QUOTE_BY_ID_SUCCESS = "GET_QUOTE_BY_ID_SUCCESS";
export const GET_QUOTE_BY_ID_FAIL = "GET_QUOTE_BY_ID_FAIL";
export const ADD_QUOTE_SUCCESS = "ADD_QUOTE_SUCCESS";
export const ADD_QUOTE_FAIL = "ADD_QUOTE_FAIL";
export const UPDATE_QUOTE_SUCCESS = "UPDATE_QUOTE_SUCCESS";
export const UPDATE_QUOTE_FAIL = "UPDATE_QUOTE_FAIL";
export const DELETE_QUOTE_SUCCESS = "DELETE_QUOTE_SUCCESS";
export const DELETE_QUOTE_FAIL = "DELETE_QUOTE_FAIL";

export const CONVERT_QUOTE_TO_PRODUCTION_ORDER_SUCCESS = "CONVERT_QUOTE_TO_PRODUCTION_ORDER_SUCCESS";
export const CONVERT_QUOTE_TO_PRODUCTION_ORDER_FAIL = "CONVERT_QUOTE_TO_PRODUCTION_ORDER_FAIL";

export const GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS";
export const GET_PRODUCTS_FAIL = "GET_PRODUCTS_FAIL";
export const GET_PRODUCT_BY_ID_SUCCESS = "GET_PRODUCT_BY_ID_SUCCESS";
export const GET_PRODUCT_BY_ID_FAIL = "GET_PRODUCT_BY_ID_FAIL";
export const ADD_PRODUCT_SUCCESS = "ADD_PRODUCT_SUCCESS";
export const ADD_PRODUCT_FAIL = "ADD_PRODUCT_FAIL";
export const UPDATE_PRODUCT_SUCCESS = "UPDATE_PRODUCT_SUCCESS";
export const UPDATE_PRODUCT_FAIL = "UPDATE_PRODUCT_FAIL";

export const DELETE_PRODUCT_BY_ID_SUCCESS = "DELETE_PRODUCT_BY_ID_SUCCESS";
export const DELETE_PRODUCT_BY_ID_FAIL = "DELETE_PRODUCT_BY_ID_FAIL";

export const GET_PRODUCT_OPTIONS_SUCCESS = "GET_PRODUCT_OPTIONS_SUCCESS";
export const GET_PRODUCTS_OPTIONS_FAIL = "GET_PRODUCTS_OPTIONS_FAIL";
export const GET_PRODUCT_TYPES_SUCCESS = "GET_PRODUCTS_TYPES_SUCCESS";
export const GET_PRODUCT_TYPES_FAIL = "GET_PRODUCT_TYPES_FAIL";
export const GET_PRODUCTS_CATEGORIES_SUCCESS = "GET_PRODUCTS_CATEGORIES_SUCCESS";
export const GET_PRODUCTS_CATEGORIES_FAIL = "GET_PRODUCTS_CATEGORIES_FAIL";
export const GET_PAINT_TYPES_SUCCESS = "GET_PAINT_TYPES_SUCCESS";
export const GET_PAINT_TYPES_FAIL = "GET_PAINT_TYPES_FAIL";
export const GET_PAINT_CATEGORIES_SUCCESS = "GET_PAINT_CATEGORIES_SUCCESS";
export const GET_PAINT_CATEGORIES_FAIL = "GET_PAINT_CATEGORIES_FAIL";

export const GET_INVENTORIES_SUCCESS = "GET_INVENTORIES_SUCCESS";
export const GET_INVENTORIES_FAIL = "GET_INVENTORIES_FAIL";
export const ADD_INVENTORY_SUCCESS = "ADD_INVENTORY_SUCCESS";
export const ADD_INVENTORY_FAIL = "ADD_INVENTORY_FAIL";
export const UPDATE_INVENTORY_SUCCESS = "UPDATE_INVENTORY_SUCCESS";
export const UPDATE_INVENTORY_FAIL = "UPDATE_INVENTORY_FAIL";
export const DELETE_INVENTORY_SUCCESS = "DELETE_INVENTORY_SUCCESS";
export const DELETE_INVENTORY_FAIL = "DELETE_INVENTORY_FAIL";

export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_FAIL = "GET_USERS_FAIL";
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
export const ADD_USER_FAIL = "ADD_USER_FAIL";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL";
export const GET_USER_BY_ID_SUCCESS = "GET_USER_BY_ID_SUCCESS";
export const GET_USER_BY_ID_FAIL = "GET_USER_BY_ID_FAIL";

export const GET_GROUPS_SUCCESS = "GET_GROUPS_SUCCESS";
export const GET_GROUPS_FAIL = "GET_GROUPS_FAIL";
export const ADD_GROUP_SUCCESS = "ADD_GROUP_SUCCESS";
export const ADD_GROUP_FAIL = "ADD_GROUP_FAIL";
export const UPDATE_GROUP_SUCCESS = "UPDATE_GROUP_SUCCESS";
export const UPDATE_GROUP_FAIL = "UPDATE_GROUP_FAIL";
export const GET_GROUP_BY_ID_SUCCESS = "GET_GROUP_BY_ID_SUCCESS";
export const GET_GROUP_BY_ID_FAIL = "GET_GROUP_BY_ID_FAIL";
export const DELETE_GROUP_SUCCESS = "DELETE_GROUP_SUCCESS";
export const DELETE_GROUP_FAIL = "DELETE_GROUP_FAIL";

export const GET_GROUPSRIGHS_SUCCESS = "GET_GROUPRIGHTS_SUCCESS";
export const GET_GROUPSRIGHTS_FAIL = "GET_GROUPRIGHTS_FAIL";
export const ADD_GROUPRIGHTS_SUCCESS = "ADD_GROUPRIGHT_SUCCESS";
export const ADD_GROUPRIGHTS_FAIL = "ADD_GROUPRIGHT_FAIL";
export const UPDATE_GROUPRIGHTS_SUCCESS = "UPDATE_GROUPRIGHTS_SUCCESS";
export const UPDATE_GROUPRIGHTS_FAIL = "UPDATE_GROUPRIGHTS_FAIL";
export const GET_GROUPRIGHTS_BY_ID_SUCCESS = "GET_GROUPRIGHTS_BY_ID_SUCCESS";
export const GET_GROUPRIGHTS_BY_ID_FAIL = "GET_GROUPRIGHTS_BY_ID_FAIL";
export const GET_GROUPRIGHTS_BY_CODE_SUCCESS = "GET_GROUPRIGHTS_BY_CODE_SUCCESS";
export const GET_GROUPRIGHTS_BY_CODE_FAIL = "GET_GROUPRIGHTS_BY_CODE_FAIL";
export const DELETE_GROUPRIGHTS_SUCCESS = "DELETE_GROUPRIGHTS_SUCCESS";
export const DELETE_GROUPRIGHTS_FAIL = "DELETE_GROUPRIGHTS_FAIL";
export const GET_RIGHTS_FOR_GROUP_SUCCESS = "GET_RIGHTS_FOR_GROUP_SUCCESS";
export const GET_RIGHTS_FOR_GROUP_FAIL = "GET_RIGHTS_FOR_GROUP_FAIL"

export const GET_GROUPSUSERS_SUCCESS = "GET_GROUPSUSERS_SUCCESS";
export const GET_GROUPSUSERS_FAIL = "GET_GROUPSUSERS_FAIL";
export const GET_GROUPS_FOR_USER_SUCCESS = "GET_GROUPS_FOR_USER_SUCCESS";
export const GET_GROUPS_FOR_USER_FAIL = "GET_GROUPS_FOR_USER_FAIL";
export const GET_USERS_IN_GROUP_SUCCESS = "GET_USERS_IN_GROUP_SUCCESS";
export const GET_USERS_IN_GROUP_FAIL = "GET_USERS_IN_GROUP_FAIL";
export const ADD_GROUPUSER_SUCCESS = "ADD_GROUPUSER_SUCCESS";
export const ADD_GROUPUSER_FAILS = "ADD_GROUPUSER_FAILS";
export const DELETE_GROUPUSERS_SUCCESS = "DELETE_GROUPUSERS_SUCCESS";
export const DELETE_GROUPUSERS_FAIL = "DELETE_GROUPUSERS_FAIL";


export const GET_PAYMENT_TERMS_SUCCESS = "GET_PAYMENT_TERMS_SUCCESS";
export const GET_PAYMENT_TERMS_FAIL = "GET_PAYMENT_TERMS_FAIL";


export const GET_RECIPES_SUCCESS = "GET_RECIPES_SUCCESS";
export const GET_RECIPES_FAIL = "GET_RECIPES_FAIL";
export const ADD_RECIPE_SUCCESS = "ADD_RECIPE_SUCCESS";
export const ADD_RECIPE_FAIL = "ADD_RECIPE_FAIL";
export const DELETE_RECIPE_SUCCESS = "DELETE_RECIPE_SUCCESS";
export const DELETE_RECIPE_FAIL = "DELETE_RECIPE_FAIL";
export const GET_RECIPE_BY_ID_SUCCESS = "GET_RECIPE_BY_ID_SUCCESS";
export const GET_RECIPE_BY_ID_FAIL = "GET_RECIPE_BY_ID_FAIL";
export const GET_RECIPES_DETAILS_SUCCESS = "GET_RECIPES_DETAILS_SUCCESS";
export const GET_RECIPES_DETAILS_FAIL = "GET_RECIPES_DETAILS_FAIL";
export const ADD_RECIPE_DETAILS_SUCCESS = "ADD_RECIPE_DETAILS_SUCCESS";
export const ADD_RECIPE_DETAILS_FAIL = "ADD_RECIPE_DETAILS_FAIL";
export const UPDATE_RECIPE_DETAILS_SUCCESS = "UPDATE_RECIPE_DETAILS_SUCCESS";
export const UPDATE_RECIPE_DETAILS_FAIL = "UPDATE_RECIPE_DETAILS_FAIL";
export const DELETE_RECIPE_DETAILS_SUCCESS = "DELETE_RECIPE_DETAILS_SUCCESS";
export const DELETE_RECIPE_DETAILS_FAIL = "DELETE_RECIPE_DETAILS_FAIL";
export const ADD_UPDATE_RECIPE_DETAILS_SUCCESS = "ADD_UPDATE_RECIPE_DETAILS_SUCCESS";
export const ADD_UPDATE_RECIPE_DETAILS_FAIL = "ADD_UPDATE_RECIPE_DETAILS_FAIL";

export const GET_OPPORTUNITIES_SUCCESS = "GET_OPPORTUNITIES_SUCCESS";
export const GET_OPPORTUNITY_BY_ID_SUCCESS = "GET_OPPORTUNITY_BY_ID_SUCCESS";
export const ADD_OPPORTUNITY_SUCCESS = "ADD_OPPORTUNITY_SUCCESS";
export const UPDATE_OPPORTUNITY_SUCCESS = "UPDATE_OPPORTUNITY_SUCCESS";
export const DELETE_OPPORTUNITY_SUCCESS = "DELETE_OPPORTUNITY_SUCCESS";
export const GET_OPPORTUNITIES_FAIL = "GET_OPPORTUNITIES_FAIL";
export const GET_OPPORTUNITY_BY_ID_FAIL = "GET_OPPORTUNITY_BY_ID_FAIL";
export const ADD_OPPORTUNITY_FAIL = "ADD_OPPORTUNITY_FAIL";
export const UPDATE_OPPORTUNITY_FAIL = "UPDATE_OPPORTUNITY_FAIL";
export const DELETE_OPPORTUNITY_FAIL = "DELETE_OPPORTUNITY_FAIL";

export const ADD_DOCUMENT_FAIL = "ADD_DOCUMENT_FAIL"
export const ADD_DOCUMENT_SUCCESS = "ADD_DOCUMENT_SUCCESS"
export const UPLOAD_DOCUMENT_SUCCESS = "UPLOAD_DOCUMENT_SUCCESS"
export const UPLOAD_DOCUMENT_FAIL = "UPLOAD_DOCUMENT_FAIL"
export const DELETE_DOCUMENT_FAIL = "DELETE_DOCUMENT_FAIL"
export const DELETE_DOCUMENT_SUCCESS = "DELETE_DOCUMENT_SUCCESS"
export const GET_DOCUMENT_BY_ID_FAIL = "GET_DOCUMENT_BY_ID_FAIL"
export const GET_DOCUMENT_BY_ID_SUCCESS = "GET_DOCUMENT_BY_ID_SUCCESS"
export const GET_DOCUMENTS_FAIL = "GET_DOCUMENTS_FAIL"
export const GET_DOCUMENTS_SUCCESS = "GET_DOCUMENTS_SUCCESS"
export const UPDATE_DOCUMENT_FAIL = "UPDATE_DOCUMENT_FAIL"
export const UPDATE_DOCUMENT_SUCCESS = "UPDATE_DOCUMENT_SUCCESS"

export const GET_PRODUCTION_SCHEDULING_SUCCESS = "GET_PRODUCTION_SCHEDULING_SUCCESS";
export const GET_PRODUCTION_SCHEDULING_FAIL = "GET_PRODUCTION_SCHEDULING_FAIL";
export const GET_PRODUCTION_SCHEDULING_BY_ID_SUCCESS = "GET_PRODUCTION_SCHEDULING_BY_ID_SUCCESS";
export const GET_PRODUCTION_SCHEDULING_BY_ID_FAIL = "GET_PRODUCTION_SCHEDULING_BY_ID_FAIL";
export const ADD_PRODUCTION_SCHEDULING_SUCCESS = "ADD_PRODUCTION_SCHEDULING_SUCCESS";
export const ADD_PRODUCTION_SCHEDULING_FAIL = "ADD_PRODUCTION_SCHEDULING_FAIL";
export const UPDATE_PRODUCTION_SCHEDULING_SUCCESS = "UPDATE_PRODUCTION_SCHEDULING_SUCCESS";
export const UPDATE_PRODUCTION_SCHEDULING_FAIL = "UPDATE_PRODUCTION_SCHEDULING_FAIL";
export const DELETE_PRODUCTION_SCHEDULING_SUCCESS = "DELETE_PRODUCTION_SCHEDULING_SUCCESS";
export const DELETE_PRODUCTION_SCHEDULING_FAIL = "DELETE_PRODUCTION_SCHEDULING_FAIL";

export const GET_PRODUCTION_ORDERS_SUCCESS = "GET_PRODUCTION_ORDERS_SUCCESS";
export const GET_PRODUCTION_ORDERS_FAIL = "GET_PRODUCTION_ORDERS_FAIL";
export const GET_PRODUCTION_ORDER_BY_ID_SUCCESS = "GET_PRODUCTION_ORDER_BY_ID_SUCCESS";
export const GET_PRODUCTION_ORDER_BY_ID_FAIL = "GET_PRODUCTION_ORDER_BY_ID_FAIL";
export const ADD_PRODUCTION_ORDER_SUCCESS = "ADD_PRODUCTION_ORDER_SUCCESS";
export const ADD_PRODUCTION_ORDER_FAIL = "ADD_PRODUCTION_ORDER_FAIL";
export const UPDATE_PRODUCTION_ORDER_SUCCESS = "UPDATE_PRODUCTION_ORDER_SUCCESS";
export const UPDATE_PRODUCTION_ORDER_FAIL = "UPDATE_PRODUCTION_ORDER_FAIL";
export const DELETE_PRODUCTION_ORDER_SUCCESS = "DELETE_PRODUCTION_ORDER_SUCCESS";
export const DELETE_PRODUCTION_ORDER_FAIL = "DELETE_PRODUCTION_ORDER_FAIL";
export const GET_PRODUCTION_ORDER_QA_BY_ID_SUCCESS = "GET_PRODUCTION_ORDER_QA_BY_ID_SUCCESS";
export const GET_PRODUCTION_ORDER_QA_BY_ID_FAIL = "GET_PRODUCTION_ORDER_QA_BY_ID_FAIL";
export const ADD_PRODUCTION_ORDER_QA_SUCCESS = "ADD_PRODUCTION_ORDER_QA_SUCCESS";
export const ADD_PRODUCTION_ORDER_QA_FAIL = "ADD_PRODUCTION_ORDER_QA_FAIL";
export const UPDATE_PRODUCTION_ORDER_QA_SUCCESS = "UPDATE_PRODUCTION_ORDER_QA_SUCCESS";
export const UPDATE_PRODUCTION_ORDER_QA_FAIL = "UPDATE_PRODUCTION_ORDER_QA_FAIL";
export const GET_PRODUCTION_ORDER_DETAILS_SUCCESS = "GET_PRODUCTION_ORDER_DETAIL_SUCCESS";
export const GET_PRODUCTION_ORDER_DETAILS_FAIL = "GET_PRODUCTION_ORDER_DETAIL_FAIL";
export const API_ERROR_RESPONSE = "API_ERROR_RESPONSE";
export const API_SUCCESS_RESPONSE = "API_SUCCESS_RESPONSE";

export const PRODUCTS_LOADING = "PRODUCTS_LOADING";

export const GET_PURCHASE_ORDERS_SUCCESS = "GET_PURCHASE_ORDERS_SUCCESS";
export const GET_PURCHASE_ORDER_SUCCESS = "GET_PURCHASE_ORDER_SUCCESS";
export const ADD_PURCHASE_ORDERS_SUCCESS = "ADD_PURCHASE_ORDERS_SUCCESS";
export const UPDATE_PURCHASE_ORDERS_SUCCESS = "UPDATE_PURCHASE_ORDERS_SUCCESS";
export const DELETE_PURCHASE_ORDER_SUCCESS = "DELETE_PURCHASE_ORDER_SUCCESS";
export const GET_PURCHASE_ORDERS_FAIL = "GET_PURCHASE_ORDERS_FAIL";
export const GET_PURCHASE_ORDER_FAIL = "GET_PURCHASE_ORDER_FAIL";
export const ADD_PURCHASE_ORDERS_FAIL = "ADD_PURCHASE_ORDERS_FAIL";
export const UPDATE_PURCHASE_ORDERS_FAIL = "UPDATE_PURCHASE_ORDERS_FAIL";
export const DELETE_PURCHASE_ORDER_FAIL = "DELETE_PURCHASE_ORDER_FAIL";
export const CHANGE_PRODUCTION_CURRENT_PANEL = "CHANGE_PRODUCTION_CURRENT_PANEL";